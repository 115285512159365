<template>
  <section :class="$style.tariffSidebarPromo">
    <template v-if="tariffName === 'demo' && (!isCurrentTariffRanOut && isSubscribed)">
      <div :class="$style.containerDemo">
        <h3 :class="$style.title">
          Тариф PRO уже у вас
        </h3>
        <p :class="$style.text">
          Демо-доступ со всеми функциями виджета действует:
        </p>
        <ul :class="$style.timer">
          <li :class="$style.timerItem">
            {{ state.daysLeftText }}
          </li>
          <li :class="$style.timerItem">
            {{ state.hoursLeftText }}
          </li>
          <li :class="$style.timerItem">
            {{ state.minutesLeftText }}
          </li>
        </ul>
        <button :class="$style.button" @click="handleDemoButtonClick">
          Продлить PRO-тариф
        </button>
      </div>
    </template>
    <template v-else-if="tariffName === 'demo' || tariffName.includes('pro') && (isCurrentTariffRanOut || !isSubscribed)">
      <div :class="$style.containerDemoOff">
        <h3 :class="$style.title">
          Вы на тарифе FREE
        </h3>
        <p :class="$style.text">
          Подключите тариф PRO <br>и пользуйтесь всеми функциями виджета без ограничений.
        </p>
        <button :class="$style.button" @click="handleProOffButtonClick(tariffName)">
          Подключить PRO-тариф
        </button>
      </div>
    </template>
    <template v-else-if="tariffName === 'pro_1_month' && !isCurrentTariffRanOut">
      <div :class="$style.containerPro">
        <h3 :class="$style.title">
          Дольше — выгоднее!
        </h3>
        <p :class="$style.text">
          Годовая подписка выйдет дешевле на 11 000 ₽.
        </p>
        <button :class="$style.button" @click="handleProButtonClick">
          Подключить PRO на год
        </button>
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { useSubscriptionStore } from '~/store/subscription'
import { useAuthStore } from '~/store/auth'
import { useApi } from '~/composables/useApi'

interface TariffSidebarPromoState {
  daysLeftText: string
  hoursLeftText: string,
  minutesLeftText: string,
  countdownInterval: Timeout | null,
  interval: Timeout | null
}

const subscriptionStore = useSubscriptionStore()
const authStore = useAuthStore()
const api = useApi()
const router = useRouter()

// data
const state = reactive<TariffSidebarPromoState>({
  daysLeftText: '... д.',
  hoursLeftText: '... ч.',
  minutesLeftText: '... мин.',
  countdownInterval: null,
  interval: null
})

// computed
const subscriptionInfo = computed(() => subscriptionStore.subscriptionInfo)
const isSubscribed = computed(() => subscriptionStore.isSubscribed)
const user = computed(() => authStore.user)
const isCurrentTariffRanOut = computed(() => new Date(subscriptionInfo.value?.dueDate).getTime() < Date.now())
const tariffName = computed(() => subscriptionInfo.value?.subscriptionPlan?.planName)

// methods
const updateCountdown = () => {
  const subscriptionDueDate = new Date(subscriptionInfo.value?.dueDate)
  const timeBeforeDueDate = getTimeBeforeDate(subscriptionDueDate)
  state.daysLeftText = `${timeBeforeDueDate.days} д.`
  state.hoursLeftText = `${timeBeforeDueDate.hours} ч.`
  state.minutesLeftText = `${timeBeforeDueDate.minutes} мин.`
}

const handleDemoButtonClick = async () => {
  await api.statisticsInnerApi.createInnerEvent({ name: 'tariff_banner_click', userId: user.value?.id, details: { type: 'demo' } })
  await router.push({ name: 'tariff' })
}

const handleProButtonClick = async () => {
  await api.statisticsInnerApi.createInnerEvent({ name: 'tariff_banner_click', userId: user.value?.id, details: { type: 'pro' } })
  await router.push({ name: 'tariff' })
}

const handleProOffButtonClick = async (tariffName: string) => {
  if (tariffName.includes('pro')) {
    await api.statisticsInnerApi.createInnerEvent({ name: 'tariff_banner_click', userId: user.value?.id, details: { type: 'pro_off' } })
  } else {
    await api.statisticsInnerApi.createInnerEvent({ name: 'tariff_banner_click', userId: user.value?.id, details: { type: 'demo_off' } })
  }
  await router.push({ name: 'tariff' })
}

const getTimeBeforeDate = (targetDate: any) => {
  const now = new Date()

  const difference = targetDate.getTime() - now.getTime()

  const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((difference % (1000 * 60)) / (1000))

  return {
    days,
    hours,
    minutes,
    seconds
  }
}

// mounted
onMounted(() => {
  updateCountdown()

  state.interval = setInterval(() => {
    updateCountdown()
  }, 1000)
})

// beforeUnmount
onBeforeUnmount(() => {
  clearInterval(state.countdownInterval)
})

</script>

<style module lang="scss">
  .tariffSidebarPromo {
    width: 280px;
    margin-bottom: 8px;
  }

  .containerDemo {
    width: 100%;
    border-radius: 8px;
    background: rgba(182, 215, 255, 0.48);
    padding: 16px 28px 20px;
  }

  .containerDemoOff {
    width: 100%;
    border-radius: 8px;
    background: #e2c9fe48;
    padding: 16px 28px 20px;
  }

  .containerPro {
    width: 100%;
    border-radius: 8px;
    background: rgba(172, 228, 201, 0.48);
    padding: 16px 28px 20px;
  }

  .containerProOff {
    width: 100%;
    border-radius: 8px;
    background: rgba(182, 215, 255, 0.48);
    padding: 16px 28px 20px;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: var(--primary);
    margin: 0;
    margin-bottom: 10px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
    text-align: left;
    color: var(--primary);
    margin: 0;
    margin-bottom: 16px;
  }

  .timer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 4px;
    margin-bottom: 16px;
    margin-top: -8px;
  }

  .buttonTooltip {
    margin: 0;
    margin-top: 4px;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .timer .timerItem {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    color: var(--primary);
  }

  .button {
    width: 100%;
    height: 36px;
    background: var(--main-blue);
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-align: center;
    border: none;
    color: var(--white);
    cursor: pointer;
  }
</style>
